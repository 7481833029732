<template>
    <!-- 전체 콘텐츠 영역 -->
    <div class="main-contents">
        <div class="news_wrap">
          <div class="path"><a href="javascript:void(0);" @click="$router.go(-1)">◀ 목록가기</a></div>

          <!-- view -->
          <div class="news-view">
            <div class="view-title">[ 공지사항 ] {{noticeInfo.newsCenterTitle}}</div>
            <div class="attatched-image ta-left" v-if="noticeInfo.attaFileSeq">
              <!-- <img :src="'/api/main/bbs/noticeImage?attaFileSeq='+noticeInfo.attaFileSeq" :alt="noticeInfo.attaFileNm" /> -->
              <ImageComp :src="'/api/main/bbs/newsCenterImage?attaFileSeq='+noticeInfo.attaFileSeq" alt="공지사항 이미지"/>
            </div>
            <div class="view-text">
                <p>
                {{noticeInfo.newsCenterCont}}
                </p>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newsCenterSeq : this.$route.params.newsCenterSeq || '',
            noticeInfo : {
                attaFileSeq : '',
                attaFileNm : '',
                newsCenterTitle : '',
                newsCenterCont : '',
            },
        }
    }, 

    beforeMount() {
        this.getNoticeDetail();
    },

    methods : {
        getNoticeDetail() {
            this.$.httpPost('/api/main/bbs/getNoticeDetail', {newsCenterSeq : this.newsCenterSeq})
                .then(res => {
                    //console.log(res);
                    this.noticeInfo = res.data.info;
                }).catch(this.$.httpErrorCommon);
        },
    }
}
</script>